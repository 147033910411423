/* global Component */
class centralContainerComponent extends Component {

    static name() {
        return "centralContainerComponent";
    }

    static componentName() {
        return "centralContainerComponent";
    }

    data() {
        return {
            componentIsLoading:false,
            viewIsLoading:true
        };
    }

    getComputed() {
        return {
            homeSections:function (){
                let homeItems = [];
                for (let homeitem of this.$store.getters.getHomeItems) {
                    let hs = this.$store.getters.getHomeSectionByCode(homeitem.fields.PageSectionSection);
                    if (!homeItems.includes(hs)) homeItems.push(hs);
                }
                return homeItems;
            }
        };
    }

    mounted() {
        return async function () {
            if(this.$store.state.itemsFullLoader) {
                this.loadPrices();
            }
        };
    }

    getWatch() {
        return {
            "$store.state.itemsFullLoader":function (){
                if(this.$store.state.itemsFullLoader) {
                    this.componentIsLoading = false;
                    this.loadPrices();
                }
            }
        };
    }

    getMethods() {
        return {
            loadPrices:this.loadPrices
        };
    }

    async loadPrices(){
        if(!this.componentIsLoading) {
            this.viewIsLoading = true;
            //console.log('items in home call 111');
            let itemCodes = new Map();
            //console.log('ITems Home 333',this.$store.getters.getHomeItems)
            for (let homeItemCode of this.$store.getters.getHomeItems) {
                //console.log('Load Item of Home',homeItemCode)
                if (!itemCodes.has(homeItemCode.fields.ItemCode)) {
                    let tempItem = this.$store.getters.getItemByCode(homeItemCode.fields.ItemCode);
                    if (tempItem)
                        itemCodes.set(homeItemCode.fields.ItemCode, tempItem);
                }
            }
            await this.$store.dispatch("updatePrices", Array.from(itemCodes.values()));
            //console.log('Ended Update',self.componentIsLoading);
            this.componentIsLoading = !this.componentIsLoading;
            this.viewIsLoading = false;
        }
    }



    getTemplate() {
        return `<div class="col-sm-12 central-container px-0">
                 <template v-if="viewIsLoading">
                          <div class="loading">
                              <i class="fas fa-spinner fa-spin fa-3x"></i>
                          </div>
                  </template>
                  <template v-else>
                      <homeItemSectionComponent :key="homeSection.sectionValue" :section="homeSection" v-for="homeSection of homeSections"></homeItemSectionComponent>
                  </template>
              </div>`;
    }
}

centralContainerComponent.registerComponent();